function uncover(teg) {
    var styleBlock = document.getElementById('slave-block' + teg);
    if (styleBlock.classList.contains('nonactive')) {
        styleBlock.classList.remove('nonactive');
        styleBlock.classList.add('active');
    } else {
        styleBlock.classList.add('nonactive');
        styleBlock.classList.remove('active');
    }
}

window.uncover = uncover;